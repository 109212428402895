.publication-quote_publicationQuote__oBHpb {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-inline-size: 319px;
    block-size: 136px;
    padding: 0 var(--space-24);
    color: var(--display-onlight-primary);
    text-align: center;
}

    .publication-quote_publicationQuote__oBHpb::after {
        content: "";
        position: absolute;
        background-color: var(--ui-border);
        inline-size: 1px;
        display: block;
        block-size: 100%;
        inset: 0 0 0 calc(var(--gap) * -0.5);
    }

    .publication-quote_publicationQuote__oBHpb:first-child::after {
        opacity: 0;
    }

    @media (max-width: 959px) {
        .publication-quote_publicationQuote__oBHpb:first-child::after {
            opacity: 1;
        }
    }

.publication-quote_blockquote__j4QHz {
    display: flex;
    flex: 0 0 72px;
    align-items: center;
    justify-content: center;
}

.publication-quote_logoContainer__ReB5u {
    max-block-size: var(--logo-container-block-size, 18px);
}

.publication-quote_logoContainer__ReB5u img {
        block-size: 100%;
        inline-size: 100%;
        -o-object-fit: contain;
           object-fit: contain;
    }

.publication-quote_logoContainer__ReB5u.publication-quote_square__6nZmW {
        --logo-container-block-size: 40px;
    }

.publication-quotes_publicationQuotesContainer__mqtlP {
    inline-size: 100%;
    max-inline-size: 1360px;
    overflow: hidden;
}

.publication-quotes_publicationQuotesWrapper__uDleW {
    --duplicates: 3;
    --gap: var(--space-12);
    --offset: calc(var(--gap) / var(--duplicates));
    --move-initial: 0%;
    --move-final: calc(-1 * (100% / var(--duplicates)) - var(--offset));

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: var(--gap);
    gap: var(--gap);
    inline-size: -moz-max-content;
    inline-size: max-content;
}

@media (min-width: 960px) {

.publication-quotes_publicationQuotesWrapper__uDleW {
        margin: 0 auto
}
    }

@media (max-width: 959px) {

.publication-quotes_publicationQuotesWrapper__uDleW {
        animation: 30s linear 0s infinite publication-quotes_slide__ZOYqf
}
    }

@keyframes publication-quotes_slide__ZOYqf {
    from {
        transform: translate3d(var(--move-initial), 0, 0);
    }
    to {
        transform: translate3d(var(--move-final), 0, 0);
    }
}

.customer-logos-section_customerLogosContainer__AIYQm {
    display: flex;
    flex-direction: column;
    align-items: var(--align-items, center);
    gap: var(--space-32);
    text-align: center;
}

    @media (max-width: 959px) {.customer-logos-section_customerLogosContainer__AIYQm {
        --align-items: flex-start
}
    }

    .customer-logos-section_customerLogosContainer__AIYQm {

    overflow: hidden;
    position: relative;
}

    .customer-logos-section_customerLogosContainer__AIYQm .customer-logos-section_backgroundImage__kOB8L {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: 1120px) {

    .customer-logos-section_customerLogosContainer__AIYQm .customer-logos-section_backgroundImage__kOB8L {
            display: none
    }
        }

