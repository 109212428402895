.publicationQuotesContainer {
    inline-size: 100%;
    max-inline-size: 1360px;
    overflow: hidden;
}

.publicationQuotesWrapper {
    --duplicates: 3;
    --gap: var(--space-12);
    --offset: calc(var(--gap) / var(--duplicates));
    --move-initial: 0%;
    --move-final: calc(-1 * (100% / var(--duplicates)) - var(--offset));

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: var(--gap);
    inline-size: max-content;

    @media (--screen-gt-lg) {
        margin: 0 auto;
    }

    @media (--screen-lt-lg) {
        animation: 30s linear 0s infinite slide;
    }
}

@keyframes slide {
    from {
        transform: translate3d(var(--move-initial), 0, 0);
    }
    to {
        transform: translate3d(var(--move-final), 0, 0);
    }
}
