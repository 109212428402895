.customerLogosContainer {
    display: flex;
    flex-direction: column;
    align-items: var(--align-items, center);
    gap: var(--space-32);
    text-align: center;

    @media (--screen-lt-lg) {
        --align-items: flex-start;
    }

    overflow: hidden;
    position: relative;
    .backgroundImage {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        @media (--screen-lt-xlg) {
            display: none;
        }
    }
}
